import {
  AuthLayout,
  AuthProvider,
  customTranslations,
} from "@/components/Auth";
import { Login } from "@/components/Auth/Login";
import { Recovery } from "@/components/Auth/Recovery";
import { Signup } from "@/components/Auth/Signup";
import { Verification } from "@/components/Auth/Verification";
import { Dashboard } from "@/components/Dashboard";
import { Error } from "@/components/Error";
import { CustomTranslations, IntlProvider, ThemeProvider } from "@ory/elements";
import "@ory/elements/style.css";
import {
  ResembleClient,
  ResembleClientProvider,
} from "@reboot-dev/resemble-react";
import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
// TODO(riley): replace this CSS with our own.
import "@ory/elements/assets/fa-brands.min.css";
import "@ory/elements/assets/fa-solid.min.css";
import "@ory/elements/assets/fontawesome.min.css";
import "@ory/elements/assets/inter-font.css";
import "@ory/elements/assets/jetbrains-mono-font.css";
import "@ory/elements/assets/normalize.css";

Sentry.init({
  dsn: "https://6579eb84392ac0d6f60c77cd98df57e1@o4506991205023744.ingest.us.sentry.io/4506991206596608",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // TODO: reduce tracesSampleRate when we start having more traffic.
  tracesSampleRate: 1.0,
  // TODO(riley,rjh): Setting the following to be non-empty means adding
  //                  Sentry-specific headers to requests, which means CORS
  //                  policies need to be updated or we get request failures.
  //                  See:
  //                    https://docs.sentry.io/platforms/javascript/performance/instrumentation/automatic-instrumentation/#tracepropagationtargets
  //                  For now, we don't send these headers to any target, since
  //                  it's unclear that the resulting Sentry features are worth
  //                  it for us.
  tracePropagationTargets: [],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const client = new ResembleClient(import.meta.env.VITE_APP_RESEMBLE_ENDPOINT);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <ResembleClientProvider client={client} key="ResembleClientProvider">
      <BrowserRouter>
        <AuthProvider key="AuthProvider">
          <ThemeProvider
            themeOverrides={{
              background: {
                surface: "#F7F5EE",
                canvas: "#F7F5EE",
                subtle: "#F7F5EE",
              },
              border: { def: "none" },
              accent: {
                def: "#8CDE85",
                muted: "#8CDE85",
                emphasis: "#8CDE85",
                disabled: "#8CDE85",
                subtle: "#8CDE85",
              },
            }}
            key="ThemeProvider"
          >
            <IntlProvider<CustomTranslations>
              locale="en"
              defaultLocale="en"
              customTranslations={customTranslations}
              key="IntlProvider"
            >
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/login"
                  element={
                    <AuthLayout callToAction="Sign in">
                      <Login />
                    </AuthLayout>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <AuthLayout callToAction="Sign up">
                      <Signup />
                    </AuthLayout>
                  }
                />
                <Route
                  path="/verification"
                  element={
                    <AuthLayout callToAction="Sign up">
                      <Verification />
                    </AuthLayout>
                  }
                />
                <Route
                  path="/recovery"
                  element={
                    <AuthLayout callToAction="Sign in">
                      <Recovery />
                    </AuthLayout>
                  }
                />
                <Route path="/error" element={<Error />} />
              </Routes>
            </IntlProvider>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
    </ResembleClientProvider>
  </StrictMode>
);
